<template>
  <div>
    <van-nav-bar :title="name + '录音情况'" />
    <van-cell v-for="(item, index) in data" :key="index">
      <template #title>
        <span> {{ item.date }}</span>
      </template>
      <template #label>
        <span>{{ item.content }}</span>
      </template>
      <template #default>
        <audio controls style="width: 100%">
          <source :src="item.url" />
        </audio>
      </template>
    </van-cell>
  </div>
</template>
<script>
export default {
  props: { phone: String, name: String },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    //处理转换结果
    dealContent(val) {
      var res = "";
      val.forEach((item) => {
        res += item.text + "\n";
      });
      return res;
    },
  },
  created() {},
  mounted() {
    if (this.phone) {
      this.$axios
        .get(`${this.$base}/admin/record/queryRecord?phone=${this.phone}`)
        .then((res) => {
          if (res.code === 200) {
            res.data.forEach((item) => {
              if (item.date) {
                item.date = this.dayjs(item.date * 1000).format(
                  "YYYY-MM-DD hh:mm:ss"
                );
              }
              if (item.content) {
                item.content = this.dealContent(JSON.parse(item.content));
              }
            });
            this.data = res.data;
          }
        });
    }
  },
};
</script>
