<template>
  <div>
    <record v-show="showRecord" :phone="phone" :name="name" />
    <visit v-show="showVisit" :phone="phone" :userId="userId" :name="name" />
  </div>
</template>
<script>
import record from "./compents/record.vue";
import visit from "./compents/visit.vue";
export default {
  components: { record, visit },
  data() {
    return {
      type: "",
      phone: "",
      userId: "",
      name: "",
      showRecord: false,
      showVisit: false,
    };
  },
  methods: {},
  created() {
    this.type = this.$route.query.type;
    this.phone = this.$route.query.phone;
    this.userId = this.$route.query.userId;
    this.name = this.$route.query.name;
    switch (this.type) {
      case "visit":
        this.showVisit = true;
        break;
      case "record":
        this.showRecord = true;
        break;
      default:
        this.showVisit = true;
        this.showRecord = true;
        break;
    }
  },
  mounted() {},
};
</script>
