<template>
  <div>
    <van-nav-bar :title="name + '跟进情况'" />
    <van-cell v-for="(item, index) in data" :key="index">
      <template #title>
        <span> {{ item.createTime }}</span>
      </template>
      <template #label>
        <span>{{ item.content }}</span>
      </template>
    </van-cell>
  </div>
</template>
<script>
export default {
  props: { phone: String, userId: String, name: String },
  data() {
    return {
      data: [],
    };
  },
  methods: {},
  created() {},
  mounted() {
    if (this.userId) {
      this.$axios
        .get(`${this.$base}/management/user/${this.userId}`)
        .then((res) => {
          if (res.code === 200) {
            res.data.userVisitLog.forEach((item) => {
              if (item.createTime) {
                item.createTime = this.dayjs(item.createTime).format(
                  "YYYY-MM-DD hh:mm:ss"
                );
              }
            });
            this.data = res.data.userVisitLog;
          }
        });
    }
  },
};
</script>
